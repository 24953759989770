import { useEffect, useState } from 'react';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';

import './style.css';
import { setObject } from '../../util/store';
import ModalMensagemHomeCarrousel from '../MensagemHome/carrousel-mensagem';
import { useMensagemHomeService } from '../../services/mensagem-home';
import { IMensagemHomeDTO } from '../../models/happy-code-api.model';

export const Home: React.FunctionComponent = () => {
  const {usuario} = useGlobalContext();
  const mensagemHomeService = useMensagemHomeService();
  const [mensagemHome, setMensagemHome] = useState<IMensagemHomeDTO[]>();

  useEffect(() => {
    if(usuario.perfil === 'SEDE'){
      mensagemHomeService.findAllCustom().then(({data}) => {
        setMensagemHome(data);
      })
      setObject('modalLogin', true);
    }
  },[usuario])
  return (
    <div className="container-home">
      <NewMenu selecionado={0} />
      <ModalMensagemHomeCarrousel
      content={mensagemHome}
      />
    </div>
  );
}