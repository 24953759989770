import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import MenuDetalheUser from '../../components/MenuDetalheUser';
import { Tags } from '../../components/Tags';
import { useUsuarioContext } from '../../context/UsuarioContext';

import './style.css';



const FranquiaCreateStep4: React.FunctionComponent = () => {
  const { usuario, setRevisao } = useUsuarioContext();
  const history = useHistory();

  const goTo = (url) => history.push(url);

  useEffect(() => {
    if (!usuario) {
      history.push(`/usuarios`);
    }
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fondo">
      <Header />
      {/* <Menu selecionado="3" /> */}
      <MenuDetalheUser />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            {/* <ul className="menu-revisao-detalhe">
              <li className="selected" style={{ width: '34%' }}>
                Dados Básicos
              </li>
              <li style={{ width: '33%' }}>
                Franquias
              </li>
              <li style={{ width: '33%' }}>
                Controle
              </li>
            </ul> */}
            <div className="box-revisao">
              <div className="dados">
                <Button className="button-line" onClick={() => goTo('/usuarios/create/franquia/step-1')} >
                  Editar
                </Button>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>Nome Completo</h5>
                  <span style={{ fontSize: 18 }}>{usuario.pessoa.nome}</span>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Email de acesso</h6>
                    <span>{usuario.login}</span>
                  </div>
                  <div className="cpf">
                    <h6>Email Pessoal</h6>
                    <span>-</span>
                  </div>
                </div>
                <div id="boxes-tags">
                  <Tags tipo={usuario.funcao.descricao} />
                  <Tags tipo={usuario.perfil.codigo} />
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button className="button-line" onClick={() => goTo('/usuarios/create/franquia/step-2')} >
                  Editar
                </Button>
                <h4>Franquias</h4>
                <div id="user-franquias-revisao">
                  {usuario.usuarioUnidades.map(usuarioUnidade => (
                    <Fragment key={usuarioUnidade.id}>
                      <h4>{usuarioUnidade.unidadeDescricao}</h4>
                      {/* <p>Ver Franquia</p> */}
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              {/* <div className="dados">
                <Button className="button-line">Editar</Button>
                <h4>Controle</h4>
                <div id="user-controle">
                  <div className="modulo">
                    <h5>Módulo 1</h5>
                    <Unicons.UilInfoCircle size="20" color="#92A9CB" />
                  </div>
                  <p>Somente Leitura</p>
                </div>
                <div id="user-controle">
                  <div className="modulo">
                    <h5>Módulo 1</h5>
                    <Unicons.UilInfoCircle size="20" color="#92A9CB" />
                  </div>
                  <p>Somente Leitura</p>
                </div>
                <div id="user-controle">
                  <div className="modulo">
                    <h5>Módulo 1</h5>
                    <Unicons.UilInfoCircle size="20" color="#92A9CB" />
                  </div>
                  <p>Somente Leitura</p>
                </div>
                <div id="user-controle">
                  <div className="modulo">
                    <h5>Módulo 1</h5>
                    <Unicons.UilInfoCircle size="20" color="#92A9CB" />
                  </div>
                  <p>Somente Leitura</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Link to="/usuarios/create/franquia/step-3">
        <div id="botao-voltar-revisao-user">
          <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
        </div>
      </Link>
    </div>
  )
}

export default FranquiaCreateStep4;