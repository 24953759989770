import { Fragment } from 'react';
import BoxRelatorio from '../../../components/BoxRelatorio';
import useRegisterLog from '../../../globalHooks/use-register-log.hook';
import useReportsHeadOffice from '../states/use-reports-head-office.hook';
import './_report-head-office.styles.scss';
import './style.css';
import MenuSidebarAndContainer from '../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';

function RelatorioSede() {
	useRegisterLog({
		descricaoEvento: 'Acesso relatório sede',
		descricaoTipo: 'Acesso a tela',
	});

	const { reportsHeadOffice } = useReportsHeadOffice();

	return (
		<MenuSidebarAndContainer itemMenuSelected={18}>
			{reportsHeadOffice.map(({ title, id, reportCards }) => {
				return (
					<Fragment key={id}>
						<div
							className="box-title"
							style={{
								marginBottom: '0px',
							}}
						>
							<h3 className="title">{title}</h3>
						</div>

						<div className="relatorios-box-wrapper">
							{id === 26 ? (
								<>
									<div className="tipo-dash" style={{ width: '87vw' }}>
										{/* Dashboards Financeiros */}
										{reportCards.some(({ tipoDash }) => tipoDash.includes('Financeiro')) && (
											<>
												<h4 style={{ fontWeight: '700', margin: '10px' }}>Financeiro</h4>
												<div className="cards-dash">
													{reportCards
														.filter(({ tipoDash }) => tipoDash.includes('Financeiro'))
														.map(({ id, title, link }) => (
															<BoxRelatorio key={id} titulo={title} link={link} />
														))}
												</div>
											</>
										)}

										{/* Dashboards de Acompanhamento */}
										{reportCards.some(({ tipoDash }) => tipoDash.includes('Acompanhamento')) && (
											<>
												<h4 style={{ fontWeight: '700', margin: '10px' }}>Acompanhamento</h4>
												<div className="cards-dash">
													{reportCards
														.filter(({ tipoDash }) => tipoDash.includes('Acompanhamento'))
														.map(({ id, title, link }) => (
															<BoxRelatorio key={id} titulo={title} link={link} />
														))}
												</div>
											</>
										)}
									</div>

								</>
							) : (
								reportCards.map(({ id, title, link }) => (
									<BoxRelatorio key={id} titulo={title} link={link} />
								))
							)}
						</div>
					</Fragment>
				);
			})}
		</MenuSidebarAndContainer>
	);
}

export default RelatorioSede;
