import { useEffect, useState } from 'react';
import './modal-change-payment.scss';

import { ICobrancaCustomDTO, ITipoPagamentoCleanDTO } from '../../../../../../models/happy-code-api.model';
import useModalChangePaymentHook from './modal-change-payment-hook';
import { Button, Checkbox, Modal } from 'antd';

interface ModalChangePaymentProps {
	open: boolean;
	cobranca: ICobrancaCustomDTO;
	onclose: () => void;
}
export default function ModalEditarPagamento({ open, cobranca, onclose }: ModalChangePaymentProps) {
	const [tipoPagamentoAll, setTipoPagamentoAll] = useState<ITipoPagamentoCleanDTO[]>();
	const {
		findPaymentTypes,
		handlePayment,
		handlePaymentSelection,
		setAlterAll,
		setTipoPagamento,
		setIsRecorrente,
		setOpenConfirm,
		alterAll,
		tipoPagamento,
		isRecorrente,
		openConfirm,
	} = useModalChangePaymentHook();

	const checkIsRecorrente = () => {
		if (cobranca?.tipoPagamento?.id === 3) {
			setIsRecorrente(true);
			setAlterAll(true);
		}
	};

	useEffect(() => {
		if (cobranca) {
			findPaymentTypes()
				.then((response) => setTipoPagamentoAll(response.data))
				.then(() => checkIsRecorrente());
		}
	}, [cobranca]);

	const closeModal = () => {
		setTipoPagamento(null);
		setIsRecorrente(false);
		setAlterAll(false);
		setOpenConfirm(false);
		onclose();
	};
	return (
		<>
			{open && (
				<div className="container-modal-payment-change">
					<div className="modal-change-payment">
						<div className="header-payment">
							<h3>Alterar Forma de Pagamento</h3>
							<p>
								Selecione o tipo de pagamento que deseja alterar para a cobrança de parcela: <strong>{cobranca?.parcela}</strong>
							</p>
						</div>
						<div className="container-payment">
							{isRecorrente
								? tipoPagamentoAll
										.filter((item) => item.id !== 3)
										.map((item) => (
											<div
												key={item.id}
												onClick={() => handlePaymentSelection(item)}
												className={tipoPagamento?.id === item?.id ? 'payment-item-select' : 'payment-item'}
											>
												<span>{item.descricao}</span>
											</div>
										))
								: tipoPagamentoAll?.map((item) => (
										<div
											key={item.id}
											onClick={() => handlePaymentSelection(item)}
											className={tipoPagamento?.id === item?.id ? 'payment-item-select' : 'payment-item'}
										>
											<span>{item.descricao}</span>
										</div>
								  ))}
						</div>
						<Checkbox
							style={{ margin: '2px 10px' }}
							value={alterAll}
							onChange={(e) => setAlterAll(e.target.checked)}
							defaultChecked={alterAll}
							checked={alterAll}
							disabled={isRecorrente}
						>
							<span>Aplicar para todas as parcelas em aberto.</span>
						</Checkbox>
						<div className="footer-payment">
							<Button id="button2" onClick={closeModal} className={'button-primary'} style={{ marginLeft: 8, minWidth: 145 }}>
								Voltar
							</Button>
							<Button className="button-primary" onClick={() => (isRecorrente ? setOpenConfirm(true) : handlePayment(cobranca, onclose))}>
								Confirmar Alteração
							</Button>
						</div>
						<Modal
							visible={openConfirm}
							onCancel={() => setOpenConfirm(false)}
							onOk={() => handlePayment(cobranca, onclose)}
							title="Atenção!"
							okText="Confirmar Alteração"
						>
							<p>Existe uma recorrência ativa, ao alterar o pagamento, todas as parcelas em aberto serão alteradas e a recorrência será cancelada.</p>
						</Modal>
					</div>
				</div>
			)}
		</>
	);
}
