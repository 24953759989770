import * as Unicons from '@iconscout/react-unicons';
import { Domain, School } from '@mui/icons-material';
import { useState } from 'react';
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useHistory } from 'react-router-dom';
import LogoGrande from '../../assets/img/login/logo-space.png';
import Logo from '../../assets/img/menu/SP color.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { ILogCleanDTO } from '../../models/happy-code-api.model';
import { useLogService } from '../../services/log.service';
import { clear } from '../../util/store';
import { Avatar } from '../Avatar';
import './style.scss';

interface Props {
	selecionado?: number;
}

const NewMenu = ({ selecionado }: Props) => {
	const { usuario, token, unidade } = useGlobalContext();
	const [menuCollapse, setMenuCollapse] = useState(true);
	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Logout',
		descricaoTipo: 'Logout',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	const menuIconClick = () =>
		menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
	const returnAcessoFuncao = (listaFuncoes: string[]) =>
		listaFuncoes.some((funcao) => usuario && funcao === usuario.funcao);

	const logout = () => {
		logService.registraLog(log).then(() => clear());
		history.push('/login');
	};

	//Para liberar o botão do crm apenas para as unidades pilotos.
	//Comentado por hora pois o piloto acabou;
	const idUnidadeUser = JSON.parse(
		localStorage.getItem('@HappyCode/login/unidade')
	);
	let piloto = false;
	if (
		idUnidadeUser?.id == null || //Acesso SEDE
		idUnidadeUser?.id == 19 || //Florianópolis
		idUnidadeUser?.id == 21 || //Goiânia
		idUnidadeUser?.id == 54 || //Uberaba
		idUnidadeUser?.id == 57 || //Conselheiro Lafaiete
		idUnidadeUser?.id == 59 || //Piracicaba
		idUnidadeUser?.id == 65 //Pouso Alegre
	) {
		piloto = true;
	}

	const acessoExternoCrme = () => {
		window.open(
			`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRME}?t=${token?.access_token}`,
			'_blank'
		);
	};

	const acessoExternoCrm = () => {
		window.open(
			`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRM}?t=${token?.access_token}&u=${unidade?.id}`,
			'_blank'
		);
	};

	const renderSubMenuEscolas = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[21, 22, 24, 25, 23].includes(selecionado) ? 'active' : null
					}
					title={'Escolas'}
					icon={
						<Domain
							fontSize="small"
							htmlColor={
								[21, 22, 24, 25, 23].includes(selecionado)
									?'#f7ebc3'
									:'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 23}
							icon={
								<Domain
									fontSize="small"
									htmlColor={
										selecionado === 21
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Grupos
							<Link to="/escolas/grupos" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 22}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 22
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Escolas
							<Link to="/escolas" />
						</MenuItem>
					)}

					{/* {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 23} icon={<Unicons.UilBuilding size="20" color={selecionado === 23 ? "#f7ebc3" : "var(--color-happy)"} />}>
        Alunos
        <Link to="/escola-alunos" />
      </MenuItem>} */}

					{returnAcessoFuncao(['ADM', 'TEC', 'GSF']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 24}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 24
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/escolas-financeiro" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 25}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 25
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							CRM
							<Link to="/escolas/crm" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuExpansao = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[21].includes(selecionado) ? 'active' : null}
					title={'Expansão'}
					icon={
						<Unicons.UilSuitcaseAlt
							size="20"
							color={
								selecionado === 21
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 34}
							icon={
								<Unicons.UilFilter
									fontSize="small"
									htmlColor={
										selecionado === 34
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							CRM - Expansão
							<Link to={''} onClick={acessoExternoCrme} />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuAcademy = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[30, 31, 33, 32,37].includes(selecionado) ? 'active' : null}
					title={'Academy'}
					icon={
						<School
							fontSize="small"
							htmlColor={
								[30, 31, 33, 32].includes(selecionado)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 31}
							icon={
								<Unicons.UilBuilding
									fontSize="small"
									htmlColor={
										selecionado === 31
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Trilhas
							<Link to="/academy/gestao-trilha/trilha" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 32}
							icon={
								<Unicons.UilBuilding
									fontSize="small"
									htmlColor={
										selecionado === 32
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Webinars
							<Link to="/academy/gestao-webinar/webinar" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 33}
							icon={
								<Unicons.UilPlug
									Size="small"
									htmlColor={
										selecionado === 33
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Conectas
							<Link to="/academy/gestao-conecta/conecta" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 30}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 30
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Materiais
							<Link to="/academy/gestao-material/cursos" />
						</MenuItem>
					)}

            {returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 36}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 36
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Questões
							<Link to="/academy/gestao-questao" />
						</MenuItem>
				   	)}
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 37}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 37
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Gestão de Materiais de Apoio
							<Link to="/academy/gestao-pasta-material" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuHubsFranquias = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[10, 11, 12, 13, 20, 99].includes(selecionado) ? 'active' : null
					}
					title={'Hubs'}
					icon={
						<Unicons.UilBuilding
							size="20"
							color={
								[10, 11, 12, 13, 20, 99].includes(selecionado)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 10}
							icon={
								<Unicons.UilSmile
									size="20"
									color={
										selecionado === 10
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Alunos
							<Link to="/alunos" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-financeiro"
							active={selecionado === 11}
							icon={
								<Unicons.UilDollarSignAlt
									size="20"
									color={
										selecionado === 11
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/financeiro" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 12}
							icon={
								<Unicons.UilBookmark
									size="20"
									color={
										selecionado === 12
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Ofertas
							<Link to="/curso-franquia/step-2" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-turmas"
							active={selecionado === 99}
							icon={
								<Unicons.UilArchway
									size="20"
									color={
										selecionado === 99
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Salas
							<Link to="/sala" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-turmas"
							active={selecionado === 13}
							icon={
								<Unicons.UilBookReader
									size="20"
									color={
										selecionado === 13
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Turmas
							<Link to="/turmas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-crm"
							active={selecionado === 20}
							icon={
								<Unicons.UilFilter
									size="20"
									color={
										selecionado === 20
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							CRM
							<Link to="" onClick={acessoExternoCrm} />
						</MenuItem>
					)}

					{/*returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-crm" active={selecionado === 30} icon={<Unicons.UilPuzzlePiece
        size="20" color={selecionado === 30 ? "#f7ebc3" : "var(--color-happy)"} />}>
        Stemplay
        <Link to="/stemplay" />
      </MenuItem>*/}
				</SubMenu>
			)
		);
	};

	const renderSubMenuFaq = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[4, 77].includes(selecionado) ? 'active' : null}
					title={'FAQ'}
					icon={
						<Unicons.UilQuestionCircle
							size="20"
							color={
								[4, 77].includes(selecionado)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 4}
							icon={
								<Unicons.UilQuestionCircle
									size="20"
									color={
										selecionado === 4
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							FAQ Hubs
							<Link to="/faq/b2c" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 77}
							icon={
								<Unicons.UilQuestionCircle
									size="20"
									color={
										selecionado === 77
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							FAQ Escolas
							<Link to="/faq/b2b" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};
	const renderSubMenuParametrizacao = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-franquias"
					className={
						[26, 28, 27, 105, 97, 96, 7, 101].includes(selecionado)
							? 'active'
							: null
					}
					title={'Parametrizações'}
					icon={
						<Unicons.UilSetting
							size="20"
							color={
								[26, 28, 27, 105, 97, 96, 7, 101].includes(selecionado)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 26}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 26
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Pagamento
							<Link to="/franquias/pagamento-tipo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-autonomia"
							active={selecionado === 105}
							icon={
								<Unicons.UilClipboardNotes
									size="20"
									color={
										selecionado === 105
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Autonomia
							<Link to="/autonomia" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 97}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 97
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Configuração splits
							<Link to="/hora" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 96}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 96
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Recursos
							<Link to="/recurso" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-campanhas"
							active={selecionado === 7}
							icon={
								<Unicons.UilBolt
									size="20"
									color={
										selecionado === 7
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Campanhas
							<Link to="/campanhas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 27}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 27
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Metas Tipo
							<Link to="/metas-tipo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 28}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 28
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Metas
							<Link to="/metas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 101}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 101
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Rodar Rotinas
							<Link to="/rodar-rotinas" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuHappyLearn = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-franquias"
					className={
						[201, 202, 203, 204, 205, 206, 207, 208, 209, 210].includes(
							selecionado
						)
							? 'active'
							: null
					}
					title={'Happy Learn'}
					icon={
						<Unicons.UilBookReader
							size="20"
							color={
								[201, 202, 203, 204, 205, 206, 207, 208, 209].includes(
									selecionado
								)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'TEC', 'GSF', 'GSA']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 201}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 201
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Conteúdos
							<Link to="/conteudos" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC', 'GSF', 'GSA']) && (
						<MenuItem
							id="menu-autonomia"
							active={selecionado === 202}
							icon={
								<Unicons.UilClipboardNotes
									size="20"
									color={
										selecionado === 202
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Competências
							<Link to="/competencias" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 203}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 203
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Habilidades
							<Link to="/habilidades" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 204}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 204
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Brasão
							<Link to="/brasao" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 206}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 206
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Tipos de Conteúdo
							<Link to="/tipo-conteudo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 207}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 207
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Medalhas
							<Link to="/medalhas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 208}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 208
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Insígnia
							<Link to="/insignia" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 209}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 209
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Notícias
							<Link to="/noticias" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 210}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 210
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Quiz
							<Link to="quiz" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuComunicacao = () => {
		return (
			<SubMenu
				id="submenu-comunicacao"
				className={[215, 112, 29].includes(selecionado) ? 'active' : null}
				title={'Comunicação'}
				icon={
					<Unicons.UilCommentDots
						size="20"
						color={
							[215, 112, 29].includes(selecionado)
								? '#f7ebc3'
								: 'var(--color-happy)'
						}
					/>
				}
			>
				{returnAcessoFuncao(['ADM', 'TEC']) && (
					<MenuItem
						id="space-avisos"
						active={selecionado === 29}
					>

						Space - Avisos
						<Link to={"/mensagem-home"} />
					</MenuItem>
				)}
				{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
					<MenuItem
						id="learn-push"
						active={selecionado === 215}
					>

						Learn - Push
						<Link to={"/push-learn"} />
					</MenuItem>
				)}
				{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
					<MenuItem
						id="learn-banner"
						active={selecionado === 112}
					>

						Learn - Banner
						<Link to={"/banner-learn"} />
					</MenuItem>
				)}
			</SubMenu>
		);
	};

	// const renderSubMenuComunicacaoFranquia = () => {
	// 	return (
	// 		<SubMenu
	// 			id="submenu-comunicacao"
	// 			className={[215, 112, 29].includes(selecionado) ? 'active' : null }
	// 			title={'Comunicação'}
	// 			icon={
	// 				<Unicons.UilCommentDots
	// 					size="20"
	// 					color={
	// 						[215, 112, 29].includes(selecionado)
	// 							? '#f7ebc3'
	// 							: 'var(--color-happy)'
	// 					}
	// 				/>
	// 			}
	// 		>
	// 			{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
	// 				<MenuItem
	// 					id="learn-push"
	// 					active={selecionado === 215}
	// 				>

	// 					Learn - Push
	// 					<Link to={"/push-learn"} />
	// 				</MenuItem>
	// 			)}

	// 		</SubMenu>
	// 	);
	// };

	const renderSubMenuHubsSede = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[1, 5, 6, 8, 9, 10, 98, 107, 108, 109].includes(selecionado)
							? 'active'
							: null
					}
					title={'Hubs'}
					icon={
						<Unicons.UilBuilding
							size="20"
							color={
								[1, 5, 6, 8, 9, 10, 98, 107, 108, 109].includes(selecionado)
									? '#f7ebc3'
									: 'var(--color-happy)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 10}
							icon={
								<Unicons.UilSmile
									size="20"
									color={
										selecionado === 10
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Alunos
							<Link to="/alunos" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 108}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 108
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Currículos
							<Link to="/curriculo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 122}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 122
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Produtos Escolas
							<Link to="/produto-escola" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 6}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 6
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Cursos
							<Link to="/cursos" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 107}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 107
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Negócios
							<Link to="/negocio" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 1}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 1
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Franquias
							<Link to="/franquias" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-financeiro-sede"
							active={selecionado === 9}
							icon={
								<Unicons.UilDollarSign
									size="20"
									color={
										selecionado === 9
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/financeiro-sede" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-taxas"
							active={selecionado === 8}
							icon={
								<Unicons.UilReceipt
									size="20"
									color={
										selecionado === 8
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Taxas
							<Link to="/taxas" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSidebarSede = () => {
		return (
			<>
				<Menu iconShape="round" className='menu-container'>
					<MenuItem
						id="menu-home"
						active={selecionado === 0}
						icon={
							<Unicons.UilHome
								size="20"
								color={
									selecionado === 0
										? '#f7ebc3'
										: 'var(--color-happy)'
								}
							/>
						}
					>
						Home
						<Link to="/" />
					</MenuItem>

					{renderSubMenuHubsSede()}
					{renderSubMenuEscolas()}
					{renderSubMenuExpansao()}
					{renderSubMenuAcademy()}
					{renderSubMenuParametrizacao()}
					{renderSubMenuHappyLearn()}
					{renderSubMenuComunicacao()}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-usuarios"
							active={selecionado === 3}
							icon={
								<Unicons.UilUsersAlt
									size="20"
									color={
										selecionado === 3
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Usuarios
							<Link to="/usuarios" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-indicadores"
							active={selecionado === 18}
							icon={
								<Unicons.UilAnalytics
									size="20"
									color={
										selecionado === 18
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Relatórios
							<Link to="/relatorio-sede" />
						</MenuItem>
					)}
					<MenuItem
						id="menu-gpt"
						active={selecionado === 102}
						icon={<Unicons.UilCommentAltDots size="20" color={selecionado === 102 ? '#f7ebc3' : 'var(--color-happy)'} />}
					>
						Happy GPT
						<Link to={'/happy-gpt'} />
					</MenuItem>
				</Menu>
			</>
		);
	};

	const renderSidebarFranquia = () => {
		return (
			<>
				<Menu iconShape="round">
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-home"
							active={selecionado === 0}
							icon={
								<Unicons.UilHome
									size="20"
									color={
										selecionado === 0
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Home
							<Link to="/franquia" />
						</MenuItem>
					)}

					{renderSubMenuHubsFranquias()}
					{renderSubMenuEscolas()}
					{/* {renderSubMenuComunicacaoFranquia()} */}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-colaboradores"
							active={selecionado === 9}
							icon={
								<Unicons.UilUsersAlt
									size="20"
									color={
										selecionado === 9
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Colaboradores
							<Link to="/colaboradores" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-rel-financeiro"
							active={selecionado === 15}
							icon={
								<Unicons.UilAnalytics
									size="20"
									color={
										selecionado === 15
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							Relatórios
							<Link to="/relatorio" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 16}
							icon={
								<Unicons.UilClipboardNotes
									size="20"
									color={
										selecionado === 16
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>
							<a
								href="https://suporte.happygrupo.com.br"
								className="chamado"
								target="blank"
							>
								Chamados
							</a>
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && unidade?.id && (
						<MenuItem
							id="menu-gpt"
							active={selecionado === 27}
							icon={
								<Unicons.UilCommentAltDots
									size="20"
									color={
										selecionado === 27
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>

							Happy GPT
							<Link to={"/happy-gpt"} />
						</MenuItem>
					)}

					{renderSubMenuFaq()}

					{returnAcessoFuncao(['ADM']) && (
						<MenuItem
							id="menu-fala-comandante"
							active={selecionado === 35}
							icon={
								<Unicons.UilMegaphone
									size="20"
									color={
										selecionado === 35
											? '#f7ebc3'
											: 'var(--color-happy)'
									}
								/>
							}
						>

							Fala Comandante
							<Link to={"/fala-comandante"} />
						</MenuItem>
					)}
					{/*returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && <MenuItem id="menu-indicadores" active={selecionado === 30} icon={<Unicons.UilPuzzlePiece size="20" color={selecionado === 30 ? "#f7ebc3" : "var(--color-happy)"} />}>
          Stemplay
          <Link to="/stemplay" />
        </MenuItem>*/}
				</Menu>
			</>
		);
	};

	return (
		<>
			<div id="header" style={{ backgroundColor: 'white' }}>
				<ProSidebar collapsed={menuCollapse}>
					<SidebarHeader>
						<div className="logotext">
							<p>
								{menuCollapse ? (
									<img src={Logo} alt="" onClick={menuIconClick} />
								) : (

									<img src={LogoGrande} alt="" onClick={menuIconClick} />

								)}
							</p>
						</div>
					</SidebarHeader>
					<SidebarContent>
						{usuario && usuario.perfil === 'SEDE'
							? renderSidebarSede()
							: renderSidebarFranquia()}
					</SidebarContent>
					<SidebarFooter>
						<Menu>
							<MenuItem
								className="item-avatar"
								icon={<Avatar base64={usuario?.arquivo} size={30} />}
							>
								<div className="box-avatar">
									<h6>{usuario?.usuario}</h6>
									<p>{usuario?.perfil}</p>
								</div>
								<Link to="/usuarios/profile" />
							</MenuItem>
							<MenuItem
								onClick={logout}
								className="item-danger"
								icon={
									<Unicons.UilExport
										style={{ transform: 'rotate(90deg)' }}
										size="20"
										color="var(--color-happy)"
									/>
								}
							>
								Sair
							</MenuItem>
						</Menu>
					</SidebarFooter>
				</ProSidebar>
			</div>
		</>
	);
};

export default NewMenu;
