import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IDashSectionDTO, IUnidadePessoaCleanDTO } from '../../../models/happy-code-api.model';
import { useDashSectionService } from '../../../services/dash-section.service';
import { Predicate } from '../../../models/predicate.model';
import { ReportCard } from '../../../types/report-card.type';
import { isGestorOrAdminSede } from '../../../util/util';

export default function useFindPowerBiDashboards() {
	const { setIsGlobalLoading, usuario } = useGlobalContext();

	const predicate = useMemo(() => new Predicate(), []);

	const { findList } = useDashSectionService();

	const [dashboards, setDashboards] = useState<IDashSectionDTO[]>([]);




	const findDashboards = async () => {
		setIsGlobalLoading(true);
    const isGestorOrAdmin = await isGestorOrAdminSede(usuario); 
       

		try {
			predicate.addOption('ativo', true);
			const response = await findList(predicate);
			const relatorios = response.data;

			if (!isGestorOrAdmin) {
				const filterData = relatorios.filter((r) => r.dashReport.id !== 8);
        
				setDashboards(filterData);
				return;
			}
      
			setDashboards(relatorios);
		} catch (error) {
			console.log(error);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		if (usuario.id) findDashboards();

		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usuario]);

	const buildDashboardDataToReportScreen = useCallback((): ReportCard[] => {
		return dashboards.map(({ dashReport, codigoPbi, nome, tipoDash }, index) => {
			return {
				id: index,
				title: nome,
				link: `/relatorio/${dashReport.codigoPbi}/${codigoPbi}`,
				tipoDash: tipoDash,
			};
		});
	}, [dashboards]);

	return {
		dashboards,
		buildDashboardDataToReportScreen,
	};
}
