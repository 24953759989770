import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, DatePicker, InputNumber, Select } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import fondo from '../../../../assets/img/matriculas/fondo.svg';
import boletoIcon from '../../../../assets/img/pagamentos/boleto.svg';
import cartaoIcon from '../../../../assets/img/pagamentos/cartao-icon.svg';
import pixIcon from '../../../../assets/img/pagamentos/pix.svg';
import posIcon from '../../../../assets/img/pagamentos/pos.svg';
import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
import { useAlunoContext } from '../../../../context/AlunoContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Operators } from '../../../../enum/operators.enum';
import {
	ICampanhaFullDTO,
	ICupomAlunoCustomDTO,
	ICursoDTO,
	IHoraUnidadeCleanDTO,
	IMatriculaCustomDTO,
	INegocioUnidadeDTO,
	IPlanoPagamentoCleanDTO,
	ITipoFaturamentoCleanDTO,
	IUnidadeTipoPagamentoDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useCampanhaService } from '../../../../services/campanha.service';
import { useCobrancaService } from '../../../../services/cobranca.service';
import { useCupomAlunoService } from '../../../../services/cupom-aluno.service';
import { useFinanceiroService } from '../../../../services/financeiro.service';
import { useMatriculaService } from '../../../../services/matricula.service';
import { useNegocioUnidadeService } from '../../../../services/negocio-unidade.service';
import { useNotificationService } from '../../../../services/notification.service';
import { usePlanoPagamentoService } from '../../../../services/plano-pagamento.service';
import { useTipoFaturamentoService } from '../../../../services/tipo-faturamento.service';
import { useUnidadeTipoPagamentoService } from '../../../../services/unidade-tipo-pagamento';
import {
	currencyFormatter,
	currencyParser,
	formatMoney,
	nowDate,
} from '../../../../util/format';
import { MatriculaProgresso } from '../../progresso';
import { useHoraUnidadeService } from '../../../../services/hora-unidade.service';
import '../../style.css';
import useEncontraMaximoDesconto from './hooks/use-encontra-maximo-desconto.hook';
import useCheckIfPageIsUpgrade from '../../../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import useSubmitUpgradeMatricula from './hooks/use-submit-upgrade-matricula.hook';
import useEncontraMaximoDescontoCapital from './hooks/use-encontra-maximo-desconto-capital.hook';
import useCalcularTotalTurmaOferta from './hooks/use-calcular-total-turma-oferta.';
import useLimitInstallmentsNumbers from './hooks/use-limit-installments-numbers.hook';
import useCalcularSaldoTempoContrato from './hooks/use-calcular-saldo-tempo-contrato.hook';
import useStep3Hook from './use-step-3.hook';

moment.locale('pt-BR');

export const MatriculasCreateStep3: React.FunctionComponent = () => {
	const { matricula, setMatricula } = useAlunoContext();
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const [isOpen, setIsOpen] = useState(false);
	const [bolsaFranquia, setBolsaFranquia] = useState<number>(0);
	const [cupons, setCupons] = useState<ICupomAlunoCustomDTO[]>([]);
	const [planoPagamentos, setPlanoPagamentos] = useState<
		IPlanoPagamentoCleanDTO[]
	>([]);
	const [cupomSelected, setCupomSelected] = useState<ICupomAlunoCustomDTO>();
	const [planoPagamentoSelected, setPlanoPagamentoSelected] =
		useState<IPlanoPagamentoCleanDTO>();
	const [campanhas, setCampanhas] = useState<ICampanhaFullDTO[]>();
	const [campanhaSelected, setCampanhaSelected] = useState<ICampanhaFullDTO>();
	const [tipoFaturamento, setTipoFaturamento] =
		useState<ITipoFaturamentoCleanDTO>();
	const [curso, setCurso] = useState<ICursoDTO>();
	const [checkboxTaxa, setCheckBoxTaxa] = useState(false);
	const [unidadeTipoPagamento, setUnidadeTipoPagamento] =
		useState<IUnidadeTipoPagamentoDTO[]>();
	const [selected, setSelected] = useState<number>(0);
	const [maxParcela, setMaxParcela] = useState<number>(0);
	const [oferta, setOferta] = useState<IHoraUnidadeCleanDTO>();

	const history = useHistory();

	const campanhaService = useCampanhaService();
	const cupomAlunoService = useCupomAlunoService();
	const planoPagamentoService = usePlanoPagamentoService();
	const tipoFaturamentoService = useTipoFaturamentoService();

	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const { submitUpgradeMatricula } = useSubmitUpgradeMatricula();

	const { encontraMaximoDesconto } = useEncontraMaximoDesconto({
		ofertaData: oferta,
	});

	const { encontraMaximoDescontoCapital } = useEncontraMaximoDescontoCapital({
		ofertaData: oferta,
	});

	const { calcularTotalTurmaOferta } = useCalcularTotalTurmaOferta({
		ofertaData: oferta,
	});

	const notification = useNotificationService();
	const [valorCurso, setValorCurso] = useState<number>(0);
	const financeiroService = useFinanceiroService();

	const [deveDiluir, setDeveDiluir] = useState<boolean>(false);
	const [descontoDiluicao, setDescontoDiluicao] = useState<number>(0);
	const [saldoLiquidoAPagar, setSaldoLiquidoAPagar] = useState<number>(0);
	const [liquidoAPagar, setLiquidoAPagar] = useState<number>(0);
	const [valorParcela, setValorParcela] = useState<number>(0);
	const [valorCupom, setValorCupom] = useState<number>(0);
	const [descontoFinal, setDescontoFinal] = useState<number>(0);
	const [negocioUnidade, setNegocioUnidade] = useState<INegocioUnidadeDTO[]>();
	const [bolsaValor, setBolsaValor] = useState<number>();
	const [bolsaMaxDesconto, setBolsaMaxDesconto] = useState<number>();
	const [ofertaAtual, setOfertaAtual] = useState<number>(0);
	const tipoPagamento = { AVISTA: 1, PARCELADO: 3, ESCOLHA: 0 };
	const pagamentoSelecionado = {
		CREDITOAVISTA: 1,
		BOLETO: 4,
		CREDITORECORRENTE: 3,
		PIX: 5,
		MAQUINAFISICA: 7,
	};
	const [pagamentoSelected, setPagamentoSelected] = useState<number>(0);
	const [current, setCurrent] = useState(tipoPagamento.ESCOLHA);
	const [boletoImg] = useState(boletoIcon);
	const [pixImg] = useState(pixIcon);
	const [posImg] = useState(posIcon);
	const unidadeTipoPagamentoService = useUnidadeTipoPagamentoService();
	const negocioUnidadeService = useNegocioUnidadeService();
	const cobrancaService = useCobrancaService();
	const matriculaService = useMatriculaService();
	const horaUnidadeService = useHoraUnidadeService();

	const [creditoAVista, setcreditoAVista] = useState(false);

	const { installmentsNumbers } = useLimitInstallmentsNumbers();

	const { calcularSaldoContratoAtual, getTypeCurrentContract } = useStep3Hook();
	const saldoContratoAtual = calcularSaldoContratoAtual();

	const typeOfCurrentContract = getTypeCurrentContract();
	const contratoAntigoIsRecorrente = typeOfCurrentContract?.codigo === 'CRR';

	const calculoDasParcelasRestantes =
		saldoContratoAtual / installmentsNumbers[installmentsNumbers.length - 1];
	const quantidadeParcelasRestantes =
		installmentsNumbers[installmentsNumbers.length - 1] ?? 0;
	const parcelasRestantes = formatMoney(calculoDasParcelasRestantes);

	const { calcularSaldoTempoContrato } = useCalcularSaldoTempoContrato();
	const { saldoTempoContrato, parcelas } = calcularSaldoTempoContrato();

	const subtotalUpgradeValue = calcularTotalTurmaOferta() - descontoFinal;

	const sumOfUpgradeValueAndCurrentEnrollmentValue = contratoAntigoIsRecorrente
		? subtotalUpgradeValue
		: saldoContratoAtual + subtotalUpgradeValue;

	const calculoDaParcelaUpgrade =
		subtotalUpgradeValue / (matricula?.financeiro?.quantidadeParcelas ?? 1);

	const parcelaUpgradeValue = formatMoney(calculoDaParcelaUpgrade);

	const getParcelaFinalValue = contratoAntigoIsRecorrente
		? calculoDaParcelaUpgrade
		: sumOfUpgradeValueAndCurrentEnrollmentValue /
				(matricula?.financeiro?.quantidadeParcelas ?? 1);

	const parcelaFinalValue = formatMoney(getParcelaFinalValue);

	useEffect(() => {
		setOfertaAtual(calcularTotalTurmaOferta());
		const { financeiro } = matricula;
		financeiro.valorOferta = calcularTotalTurmaOferta();
		setMatricula({ ...matricula, idTipoPagamento: pagamentoSelected });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagamentoSelected]);

	const findNegocioUnidade = () => {
		const predicate = new Predicate();
		predicate.addOption('unidade.id', unidade?.id);
		predicate.addOption('id', matricula?.turmas?.[0]?.negocioUnidade.id);
		negocioUnidadeService
			.find(predicate)
			.then((response) => setNegocioUnidade(response.data.content));
	};

	const findTipoPagamento = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('tipoCobranca.id', current);
		predicate.addOption('unidade.id', unidade.id);
		predicate.addOption('ativo', true);
		unidadeTipoPagamentoService
			.custom(predicate)
			.then((response) => setUnidadeTipoPagamento(response.data))
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (current > 0) {
			findTipoPagamento();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current]);

	useEffect(() => {
		setMatricula({ ...matricula, idTipoPagamento: pagamentoSelected });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagamentoSelected]);

	const calcularCupom = (financeiro) => {
		financeiro.cupomDesconto = null;
		let valor = cupomSelected?.valor;

		if (valor == 0)
			valor =
				(matricula.financeiro.valorOferta * cupomSelected.percentual) / 100;

		if (valor) financeiro.cupomDesconto = { valor: valor };
	};

		const setValores = (m: IMatriculaCustomDTO) => {
			calcularCupom(m.financeiro);
			financeiroService.calcular(m.financeiro, ofertaAtual);
			setDescontoFinal(
				financeiroService.desconto(m.financeiro, ofertaAtual)
			);
			setValorCupom(m.financeiro.cupomDesconto?.valor);
			setLiquidoAPagar(ofertaAtual - m.financeiro.desconto);
			setValorParcela(
				calcularTotalTurmaOferta() / m.financeiro.quantidadeParcelas
					- m.financeiro.desconto
			);

			return m;
		};

	const atualizarBolsa = (bolsaPer) => {
		setMatricula((prev) =>{
			const novaMatricula = {
				...prev,
				financeiro: { ...prev.financeiro, desconto: bolsaPer },
			};
		
			financeiroService.calcular(novaMatricula.financeiro, ofertaAtual);
			setLiquidoAPagar(ofertaAtual);
			setValorParcela(ofertaAtual / novaMatricula.financeiro.quantidadeParcelas);

			return novaMatricula 
		
		})
	};

const alterarBolsa = (bolsa: number) => {
  setBolsaFranquia(bolsa);

  setMatricula((prev) => {
    const novaMatricula = { 
      ...prev, 
      financeiro: { ...prev.financeiro, desconto: bolsa },
    };
    const matriculaCalculada = setValores(novaMatricula);

    const vlDesconto = ofertaAtual * (bolsa / 100);
    setBolsaValor(vlDesconto);

    return matriculaCalculada;
  });
};

	const alterarBolsaValor = (vlBolsa: number) => {
		setBolsaValor(vlBolsa);
		const totalPer = (vlBolsa / ofertaAtual) * 100; /* valor informado em (%) */

		setBolsaFranquia(totalPer);
		const validarUnidadeSplit = unidade.capital
			? encontraMaximoDescontoCapital()
			: encontraMaximoDesconto();
		const vlMaxDesconto = ofertaAtual - validarUnidadeSplit;
		setBolsaMaxDesconto(vlMaxDesconto);

		atualizarBolsa(totalPer);
	};

	useEffect(() => {
		matricula.financeiro.desconto = bolsaFranquia;
		setMatricula((prev) => {
			const novaMatricula = {
				...prev,
				financeiro: { ...prev.financeiro, desconto: bolsaFranquia },
			};
			return setValores(novaMatricula);
		});
	
		// eslint-disable-next-line
	}, [bolsaFranquia]);

	useEffect(() => {
		setMatricula((prev) => {
			const percentualCampanha = campanhaSelected?.campanhaPlanoPagamentos?.find(
				(cpp) =>
					cpp.planoPagamentoNumeroParcelas ===
					planoPagamentoSelected?.numeroParcelas
			)?.percentual;

			const novaMatricula = {
				...prev,
				financeiro: { ...prev.financeiro, campanha: campanhaSelected, descontoCampanha: percentualCampanha },
			}

			return setValores(novaMatricula);
		})
		// eslint-disable-next-line
	}, [campanhaSelected]);

	const handleCampanhaChange = (value: number) => {
		const { financeiro } = matricula;
		const campanha = campanhas?.find((campanha) => campanha.id === value);

		setCampanhaSelected(campanha);

		const isCampanhaAcelera = campanha?.codigo.includes('Acelera');
		if (isCampanhaAcelera) {
			setDeveDiluir(false);
			setDescontoDiluicao(0);
		}
	};

	const handleCupomChange = (id: number) => {
		let cupom = null;

		if (id) cupom = cupons.find((cupom) => cupom.id === id);

		setCupomSelected(cupom);
	};

	const handlePlanoPagamentoChange = (value: number) => {
		const planoPagamento = planoPagamentos.find(
			(planoPagamento) => planoPagamento.numeroParcelas === value
		);
		setPlanoPagamentoSelected(planoPagamento);
		const quantidadeParcelas = planoPagamento?.numeroParcelas;
		const { financeiro } = matricula;
		setMatricula({
			...matricula,
			financeiro: {
				...financeiro,
				quantidadeParcelas,
			},
		});
	};

	const fecthCampanha = () => {
		const predicate = new Predicate();
		predicate.addOption('campanhaUnidades.unidade.id', unidade.id);
		predicate.addOption('dataInicio', nowDate(), Operators.LESS_THAN_OR_EQUAL);
		predicate.addOption('dataFim', nowDate(), Operators.GREATER_THAN_OR_EQUAL);

		return campanhaService
			.findFullList(predicate)
			.then(({ data }) => setCampanhas(data))
			.catch((error) => console.log(error));
	};

	const findHoraVenda = async () => {
		const predicate = new Predicate();
		predicate.addOption('unidade.id', unidade.id);

		try {
			const response = await horaUnidadeService.findClean(predicate);
			setOferta(response.data.content[0]);
			setOfertaAtual(calcularTotalTurmaOferta());
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		findHoraVenda();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valorCurso]);

	const fecthCupom = () => {
		cupomAlunoService
			.findByIdAluno(matricula.aluno.id)
			.then(({ data }) => setCupons(data))
			.catch((error) => console.log(error));
	};

	const fecthPlanoPagamento = () => {
		planoPagamentoService
			.findCleanList()
			.then(({ data }) => setPlanoPagamentos(data))
			.catch((error) => console.log(error));
	};

	const fecthTipoFaturamento = () => {
		tipoFaturamentoService
			.findCleanList()
			.then(({ data }) => setTipoFaturamento(data[0]))
			.catch((error) => console.log(error));
	};

	const handleVencimentoChange = (value: number) => {
		matricula.diaVencimento = value;
		setMatricula({ ...matricula });
	};

	const inicializar = async () => {
		setIsGlobalLoading(true);
		findNegocioUnidade();
		findHoraVenda();
		setMatricula((prev) => {
			const novaMatricula = { ...prev };
			const {financeiro} = novaMatricula;

			if (!novaMatricula.rematricula) {
				novaMatricula.financeiroRematricula = null;
				fecthCupom();
			}
			financeiro.valorOferta = calcularTotalTurmaOferta();
			setOfertaAtual(calcularTotalTurmaOferta());
			if (financeiro.quantidadeParcelas == null) {
				financeiro.desconto = 0;
				financeiro.campanha = null;
			}
			fecthPlanoPagamento();
			fecthTipoFaturamento();
			fecthCampanha();
	
			setBolsaFranquia(financeiro?.desconto);
			novaMatricula.financeiro = financeiro;
			financeiro.valorTotalComDesconto = calcularTotalTurmaOferta();
			novaMatricula.dataInicioContrato = moment(Date.now()).toDate();
			return setValores(novaMatricula);
		})
		setValorCurso(calcularTotalTurmaOferta());
	};

	useEffect(() => {
		inicializar();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valorCurso]);

	useEffect(() => {
		if (unidade.id && matricula?.turmaCurso?.id) {
			setIsGlobalLoading(true);
			Promise.all([fecthCampanha()]).finally(() => setIsGlobalLoading(false));
		} else if (unidade.id) {
			setIsGlobalLoading(true);
			Promise.all([fecthCampanha()]).finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id, matricula.turmaCurso.id]);

	useEffect(() => {
		if (cupomSelected?.id) {
			setMatricula((prev) => ({
				...prev,
				cupomAluno: cupomSelected,
			}));
			setCampanhaSelected(null);
			setBolsaFranquia(0);
		} else matricula.cupomAluno = null;
		setMatricula((prev) => {
			const novaMatricula = { ...prev };
			return setValores(novaMatricula);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cupomSelected]);

	useEffect(() => {
		if (planoPagamentoSelected?.numeroParcelas) {

			setMatricula((prev) => {
				const novaMatricula = {
					...prev,
				}
				const { financeiro } = novaMatricula;
				financeiro.quantidadeParcelas = planoPagamentoSelected?.numeroParcelas;
				
				financeiro.descontoCampanha =
				campanhaSelected?.campanhaPlanoPagamentos?.find(
					(cpp) =>
						cpp.planoPagamentoNumeroParcelas === financeiro.quantidadeParcelas
				)?.percentual;
			
				novaMatricula.financeiro = financeiro;
				return setValores(novaMatricula);
			})

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [planoPagamentoSelected]);

	useEffect(() => {
		if (cupons) handleCupomChange(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cupons]);

	useEffect(() => {
		if (planoPagamentos)
			handlePlanoPagamentoChange(matricula.financeiro.quantidadeParcelas);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [planoPagamentos]);

	useEffect(() => {
		if (campanhas?.length > 0)
			handleCampanhaChange(matricula.financeiro.campanha?.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campanhas]);

	const definirSaldoAPagar = async (deveDiluir) => {
		const { financeiro } = matricula;
		financeiro.deveDiluirSaldoAPagar = deveDiluir;
		financeiro.saldoAPagar = 0;
		if (
			matricula.rematricula &&
			matricula.financeiroRematricula &&
			financeiro.deveDiluirSaldoAPagar
		) {
			const response = await financeiroService.saldoAPagarResponse(
				matricula.financeiroRematricula.id
			);

			financeiro.saldoAPagar = response.data;
		}
		financeiroService.calcular(financeiro, ofertaAtual);

		setSaldoLiquidoAPagar(financeiroService.saldoLiquidoAPagar(financeiro));
		setLiquidoAPagar(financeiro.valorTotalComDesconto);
		setValorParcela(financeiro.valorMensalidadeComDesconto);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		definirSaldoAPagar(deveDiluir);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deveDiluir]);

	useEffect(() => {
		matricula.financeiro.descontoDiluicao = descontoDiluicao;
		financeiroService.calcular(matricula.financeiro, ofertaAtual);

		setSaldoLiquidoAPagar(
			financeiroService.saldoLiquidoAPagar(matricula.financeiro)
		);
		setLiquidoAPagar(matricula.financeiro.valorTotalComDesconto);
		setValorParcela(matricula.financeiro.valorMensalidadeComDesconto);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descontoDiluicao]);

	function onChangeCheckBoxTaxa(e: any) {
		setCheckBoxTaxa(e.target.checked);
	}

	const menuInputPercentual = useMemo(() => {
		return (
			<>
				<div className="input-box" style={{ marginBottom: 0 }}>
					<p>Desconto (%)</p>
					<InputNumber
						style={{ width: '100%' }}
						onChange={(e) => alterarBolsa(e)}
						type={'number'}
						defaultValue={0}
						maxLength={5}
						min={0}
						max={
							unidade.capital
								? 100 - (encontraMaximoDescontoCapital() / ofertaAtual) * 100
								: 100 - (encontraMaximoDesconto() / ofertaAtual) * 100
						}
						className="input"
						value={parseFloat(
							bolsaFranquia
								?.toFixed(8)
								?.replace(/(\d)(?=(\d{8})+(?!\d))/g, '$1.')
						)}
					/>
				</div>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		bolsaFranquia,
		valorParcela,
		matricula.valorTaxa,
		matricula?.diasProrrogacao,
	]);

	const menuInputValor = useMemo(() => {
		return (
			<>
				<div className="input-box" style={{ marginBottom: 0 }}>
					<p>Desconto (R$)</p>
					<InputNumber
						style={{ width: '100%' }}
						onChange={(e) => alterarBolsaValor(e)}
						type={'number'}
						defaultValue={0}
						maxLength={7}
						min={0}
						max={bolsaMaxDesconto}
						className="input"
						value={parseFloat(
							bolsaValor?.toFixed(3)?.replace(/(\d)(?=(\d{7})+(?!\d))/g, '$1.')
						)}
					/>
				</div>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		bolsaValor,
		valorParcela,
		matricula.valorTaxa,
		matricula?.diasProrrogacao,
	]);

	const DivTaxa = useMemo(() => {
		const validarTaxa = () => {
			let valor;
			if (checkboxTaxa) {
				valor = matricula?.valorTaxa
					? matricula?.valorTaxa
					: oferta?.valorSplitTaxa;
				setMatricula({ ...matricula, valorTaxa: valor });
			}
			if (!checkboxTaxa) {
				setMatricula({ ...matricula, valorTaxa: null });
				valor = 0;
			}
			return valor;
		};

		return (
			<>
				{!isUpgradePage && (
					<div className="input-box">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-around',
							}}
						>
							<p>Taxa de matricula</p>
							<Checkbox
								id="check-box-estorno"
								checked={checkboxTaxa}
								onChange={onChangeCheckBoxTaxa}
							/>
							<InputNumber
								disabled={!checkboxTaxa}
								formatter={currencyFormatter}
								parser={currencyParser}
								style={{ width: '100%', marginLeft: '15px' }}
								decimalSeparator=","
								// min={checkboxTaxa ? oferta?.valorSplitTaxa : 0}
								min={19.9}
								defaultValue={validarTaxa()}
								value={validarTaxa()}
								onChange={(e) => setMatricula({ ...matricula, valorTaxa: e })}
							/>
						</div>
					</div>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		matricula?.valorTaxa,
		checkboxTaxa,
		bolsaValor,
		valorParcela,
		matricula?.diasProrrogacao,
	]);

	function disabledDate(current) {
		if (!current) {
			return true;
		}

		const currentDate = moment().startOf('day');
		if (current.valueOf() < currentDate.valueOf()) {
			return true;
		}

		const dayOfMonth = current.date();

		if ([5, 10, 15, 20].includes(dayOfMonth)) {
			const maxProrrogacaoDate = currentDate.add(oferta?.numeroProrrogacaoDiasMatricula, 'days');
			return current.valueOf() > maxProrrogacaoDate.valueOf();
		} else {
			return true;
		}
	}

	function disabledDateContrato(current) {
		return current && current.valueOf() < moment(Date.now()).add(-1, 'days');
	}

	const DivProrrogacao = useMemo(() => {
		return (
			<>
				{!isUpgradePage && (
					<div className="input-box" style={{ width: '100%' }}>
						<p>Prorrogação da segunda parcela</p>
						<DatePicker
							allowClear={true}
							placeholder={'Nova data'}
							size={'large'}
							format={'DD/MM/YYYY'}
							disabledDate={disabledDate}
							disabled={!checkboxTaxa}
							onChange={(e) => {
								if (e) {
									const selectedDate = moment(e);
									const currentDate = moment().subtract(1, 'days');
									const diffInDays = selectedDate.diff(currentDate, 'days');
									setMatricula({ ...matricula, diasProrrogacao: diffInDays });
								} else {
									setMatricula({ ...matricula, diasProrrogacao: null });
								}
							}}
							getPopupContainer={(triggerNode) => {
								return triggerNode.parentElement;
							}}
						></DatePicker>
					</div>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		matricula?.diasProrrogacao,
		matricula?.valorTaxa,
		checkboxTaxa,
		bolsaValor,
		valorParcela,
	]);

	const handleChange = (e) => {
		const newDataInicioContrato = e?.toDate();
		setMatricula({ ...matricula, dataInicioContrato: newDataInicioContrato });
	};

	function DivInicioContrato(): ReactElement {
		return (
			<>
				{!isUpgradePage && (
					<div className="input-box" style={{ width: '100%' }}>
						<p>Data inicio contrato</p>
						<DatePicker
							value={moment(
								matricula.dataInicioContrato
									? matricula.dataInicioContrato
									: null
							)}
							allowClear={true}
							placeholder={'Nova data'}
							size={'large'}
							format={'DD/MM/YYYY'}
							disabledDate={disabledDateContrato}
							onChange={handleChange}
							getPopupContainer={(triggerNode) => {
								return triggerNode.parentElement;
							}}
						></DatePicker>
					</div>
				)}
			</>
		);
	}

	function DivCampanha(): ReactElement {
		return (
			<>
				<div className="input-box" style={{display: 'none'}}>
					<p>Campanha</p>

					<Select
						style={{ width: '100%' }}
						placeholder="Selecione uma campanha"
						optionFilterProp="children"
						value={campanhaSelected?.id}
						onChange={(value) => handleCampanhaChange(value)}
						allowClear
						disabled={cupomSelected != null}
					>
						{campanhas &&
							campanhas.map((campanha) =>
								campanha.campanhaCursos.map((campanhaCurso) =>
									curso?.id === campanhaCurso?.cursoId &&
									!matricula?.rematricula &&
									!campanha.codigo.includes('Acelera') ? (
										<Select.Option key={campanha.id} value={campanha.id}>
											{campanha.codigo}
										</Select.Option>
									) : curso?.id === campanhaCurso?.cursoId &&
									  matricula.rematricula ? (
										<Select.Option key={campanha.id} value={campanha.id}>
											{campanha.codigo}
										</Select.Option>
									) : curso?.id === campanhaCurso?.cursoId ? (
										<Select.Option key={campanha.id} value={campanha.id}>
											{campanha.codigo}
										</Select.Option>
									) : curso?.id === campanhaCurso?.cursoId &&
									  curso?.negocio.id === 6 &&
									  (unidade.id === 57 ||
											unidade.id === 25 ||
											unidade.id === 40 ||
											unidade.id === 12 ||
											unidade.id === 70) ? (
										<Select.Option key={campanha.id} value={campanha.id}>
											{campanha.codigo}
										</Select.Option>
									) : null
								)
							)}
					</Select>
				</div>
			</>
		);
	}

	function DivVencimento(): ReactElement {
		return (
			<>
				<div className="input-box">
					<p>
						Dia do Vencimento <span style={{ color: 'red' }}>*</span>
					</p>
					<Select
						style={{ width: '100%' }}
						placeholder="Selecione um vencimento"
						optionFilterProp="children"
						value={matricula.diaVencimento}
						onChange={(value) => handleVencimentoChange(value)}
					>
						<Select.Option value={5}>5</Select.Option>
						<Select.Option value={10}>10</Select.Option>
						<Select.Option value={15}>15</Select.Option>
						<Select.Option value={20}>20</Select.Option>
					</Select>
				</div>
			</>
		);
	}

	function DivCupom(): ReactElement {
		return (
			<>
				<div className="input-box" style={{ marginBottom: 0 , display:'none' }}>
					<p>Cupom</p>
					{matricula.financeiro && (
						<Select
							style={{ width: '100%' }}
							value={cupomSelected?.id}
							onChange={(e) => handleCupomChange(e)}
							placeholder={
								cupons.length == 0
									? 'nenhum cupom encontrado'
									: 'selecione um cupom'
							}
							disabled={cupons.length == 0}
							allowClear
						>
							{cupons?.map((cupom, index) => (
								<Select.Option key={index} value={cupom.id}>
									{cupom.cupomNome + ' (#' + cupom.id + ')'}
								</Select.Option>
							))}
						</Select>
					)}
				</div>
				<h6 style={{ paddingBottom: '15px' , display:'none'}}>
					Valor do cupom: {formatMoney(valorCupom || 0)}
				</h6>
			</>
		);
	}

	const quantidadeParcelas = Array.from(
		{ length: 12 },
		(_, index) => index + 1
	);

	const numeroMaximoDeParcelaBaseadoOferta = Array.from(
		{ length: oferta?.numeroMaximoParcela },
		(e, i) => i + 1
	);

	function DivQtdParcelas(): ReactElement {
		return (
			<div className="input-box">
				<p>
					Quantidade de parcelas <span style={{ color: 'red' }}>*</span>
				</p>
				{matricula.financeiro && (
					<Select
						style={{ width: '100%' }}
						value={planoPagamentoSelected?.numeroParcelas}
						onChange={(e) => handlePlanoPagamentoChange(e)}
					>
						{pagamentoSelected === 1 && current === 1 ? (
							<>
								{quantidadeParcelas.map((item) => (
									<Select.Option key={item} value={item}>
										{item}x
									</Select.Option>
								))}
							</>
						) : (pagamentoSelected === 2 ||
								pagamentoSelected === 3 ||
								pagamentoSelected === 4 ||
								pagamentoSelected === 5) &&
						  current === 1 ? (
							<Select.Option key={1} value={1}>
								1x
							</Select.Option>
						) : !matricula.rematricula && !isUpgradePage ? (
							numeroMaximoDeParcelaBaseadoOferta.map((parcela) => (
								<Select.Option key={parcela} value={parcela}>
									{parcela}x
								</Select.Option>
							))
						) : isUpgradePage && ![1, 3].includes(pagamentoSelected) ? (
							parcelas.map((parcela) => (
								<Select.Option key={parcela} value={parcela}>
									{parcela}x
								</Select.Option>
							))
						) : (
							numeroMaximoDeParcelaBaseadoOferta.map((parcela) => (
								<Select.Option key={parcela} value={parcela}>
									{parcela}x
								</Select.Option>
							))
						)}
					</Select>
				)}
			</div>
		);
	}

	const finishPagamento = async (cobrancaUnica: boolean) => {
		if (checkboxTaxa && oferta?.valorSplitTaxa === null) {
			notification({
				description:
					'É necessário desabilitar o campo taxa, ou informar o valor.',
				type: 'warning',
				message: 'Aviso',
			});
			return;
		}

		if (
			current === tipoPagamento.PARCELADO &&
			matricula.diaVencimento === null
		) {
			notification({
				description: 'Informe a data de vencimento!',
				type: 'warning',
				message: 'Aviso',
			});
			return;
		}

		if (isUpgradePage) {
			const calculoTotalTurmaOferta = calcularTotalTurmaOferta();

			return submitUpgradeMatricula(
				cobrancaUnica,
				pagamentoSelected,
				sumOfUpgradeValueAndCurrentEnrollmentValue,
				calculoTotalTurmaOferta,
				saldoContratoAtual,
				getParcelaFinalValue,
				contratoAntigoIsRecorrente
			);
		}

		if(!matricula.dataInicioContrato){
			notification({
				description: 'Informe a data de inicio do contrato!',
				type: 'warning',
				message: 'Aviso',
			});
			return;
		}

		setIsGlobalLoading(true);

		try {
			const { data: matriculaGerada } = await matriculaService.createFull({
				...matricula,
				cobrancaUnica,
			});

			setMatricula({ ...matricula, id: matriculaGerada.id });

			setIsGlobalLoading(false);
			if (matricula.financeiro.valorMensalidadeComDesconto === 0) {
				history.push('/matriculas/create/step-5');
			}

			if (pagamentoSelected === 7) {
				history.push('/matriculas/create/step-5');
			}

			if (matriculaGerada.id !== null && pagamentoSelected !== 7) {
				history.push(`/matriculas/create/step-4/${matriculaGerada.id}`);
			}
		} catch (error) {
			setIsGlobalLoading(false);
		}
	};

	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();

	const linkToBackButton = isUpgradePage ? idFinanceiro : '';

	function DivNavegacao(): ReactElement {
		return (
			<div
				className="botoes"
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: '16px',
				}}
			>
				<Link
					to={`/matriculas/create/curso/${linkToBackButton}`}
					className="button-second"
					style={{ width: '90px', height: '50px' }}
				>
					Voltar
				</Link>
				<Button
					onClick={() => finishPagamento(current === 1)}
					className="button-primary"
					style={{ width: 345 }}
				>
					Gerar pré matrícula
				</Button>
			</div>
		);
	}

	interface PropsDIR {
		label: string;
		valor: number;
	}

	const DivItemResumo: React.FunctionComponent<PropsDIR> = ({
		label,
		valor,
	}: PropsDIR) => {
		return (
			<div className="registro">
				<h4>{label}</h4>
				<h5 id={valor < 0 ? 'red' : ''}>{formatMoney(valor || 0)}</h5>
			</div>
		);
	};

	const DivItemResumoBox = ({
		label,
		value,
	}: {
		label: string;
		value: string;
	}) => {
		return (
			<div className="registro">
				<h4>{label}</h4>
				<h5>{value}</h5>
			</div>
		);
	};

	function DivResumoUpgrade(): ReactElement {
		return (
			<div className={'col-2'}>
				<h4>Resumo</h4>

				<div className="line"></div>

				<h5>Contrato Atual</h5>
				<DivItemResumo label="Saldo do contrato" valor={saldoContratoAtual} />
				<DivItemResumoBox
					label="Parcelas restantes"
					value={`${quantidadeParcelasRestantes}x de ${parcelasRestantes}`}
				/>
				<DivItemResumoBox
					label="Meses restantes de contrato"
					value={`${saldoTempoContrato} meses`}
				/>
				<DivItemResumoBox
					label="Forma de pagamento"
					value={`${typeOfCurrentContract?.descricao ?? '-'}`}
				/>

				<div className="line"></div>

				<h5>Upgrade</h5>
				<DivItemResumo
					label="Valor do Upgrade"
					valor={calcularTotalTurmaOferta()}
				/>
				<DivItemResumo label="Descontos*" valor={-descontoFinal} />
				<DivItemResumoBox
					label="Forma de pagamento"
					value={`${
						matricula?.financeiro?.quantidadeParcelas ?? 1
					}x de ${parcelaUpgradeValue}`}
				/>

				<div className="line"></div>

				<DivItemResumo
					label="Valor total do contrato"
					valor={sumOfUpgradeValueAndCurrentEnrollmentValue}
				/>
				<DivItemResumoBox
					label={'Parcelamento final'}
					value={`${
						matricula?.financeiro?.quantidadeParcelas ?? 1
					}x de ${parcelaFinalValue}`}
				/>

				<div className="line"></div>
				<h6>
					*O desconto aplicado ao valor do curso é o de maior valor entre bolsa,
					campanha e cupom
				</h6>
			</div>
		);
	}

	function DivResumoMatriculaRematricula(): ReactElement {
		return (
			<>
				<div className="col-2">
					<h4>Resumo</h4>
					<div className="line"></div>
					<DivItemResumo
						label="Valor do curso"
						valor={calcularTotalTurmaOferta()}
					/>

					<DivItemResumo label="Descontos*" valor={-descontoFinal} />

					{matricula.rematricula && (
						<DivItemResumo
							label="Saldo a pagar (com desconto)"
							valor={saldoLiquidoAPagar}
						/>
					)}
					<DivItemResumo
						label="Total a pagar"
						valor={matricula?.financeiro.valorTotalComDesconto}
					/>
					<div className="line"></div>
					<div className="registro">
						<h4>Parcelas</h4>
						<h5>
							{matricula?.financeiro?.quantidadeParcelas ?? 1}
							{' x '}
							{isNaN(matricula?.financeiro.valorMensalidadeComDesconto)
								? 0
								: formatMoney(
										matricula?.financeiro.valorMensalidadeComDesconto
								  )}
						</h5>
					</div>
					<div className="line"></div>
					<h6>
						*O desconto aplicado ao valor do curso é o de maior valor entre
						bolsa, campanha e cupom
					</h6>
				</div>
			</>
		);
	}

	function DivResumo(): ReactElement {
		return (
			<>
				{isUpgradePage && <DivResumoUpgrade />}
				{!isUpgradePage && <DivResumoMatriculaRematricula />}
			</>
		);
	}

	const tiposPagamento = () => {
		return (
			<div className="container-fondo">
				<Header />
				<MatriculaProgresso step={3} percent={60} />
				<div className="franquia">
					<div className="user-row" id="row-aluno">
						<h3>Vamos definir o plano de pagamento</h3>
						<div className="matriculas">
							<div className="pagamentos">
								<div
									onClick={() => {
										setSelected(1), setCurrent(tipoPagamento.AVISTA);
										setcreditoAVista(true);
									}}
									className="pagamento-credito"
									style={{
										marginRight: '10px',
										backgroundColor: selected === 1 ? '#1a4da1' : '',
										cursor: 'pointer',
									}}
								>
									<Unicons.UilMoneyWithdraw
										color={selected === 1 ? 'white' : '#0C306C'}
									/>
									<p
										style={{ color: selected === 1 ? 'white' : '' }}
										className="cartao-texto"
									>
										Pagamento à vista
									</p>
								</div>
								<div
									onClick={() => {
										setSelected(2), setCurrent(tipoPagamento.PARCELADO);
										setcreditoAVista(false);
									}}
									className="pagamento-credito"
									style={{
										backgroundColor: selected === 2 ? '#1a4da1' : '',
										cursor: 'pointer',
									}}
								>
									<Unicons.UilTransaction
										color={selected === 2 ? 'white' : '#0C306C'}
									/>
									<p
										style={{ color: selected === 2 ? 'white' : '' }}
										className="cartao-texto"
									>
										Pagamento parcelado
									</p>
								</div>
							</div>
						</div>
						{unidadeTipoPagamento ? (
							<div>
								<h3>Tipo de pagamento</h3>
								<div className="matriculas">
									<div className="pagamentos">
										{unidadeTipoPagamento?.map((tipo) =>
											tipo.tipoPagamento.codigo === 'CRC' && creditoAVista ? (
												<div
													key={tipo?.id}
													onClick={() =>
														setPagamentoSelected(
															pagamentoSelecionado.CREDITOAVISTA
														)
													}
													className="pagamento-credito"
													style={{ marginRight: '10px', cursor: 'pointer' }}
												>
													<img src={cartaoIcon} width={35}></img>
													<p className="cartao-texto">
														Cartão de crédito à vista
													</p>
												</div>
											) : tipo.tipoPagamento.codigo === 'BOL' ? (
												<div
													key={tipo.id}
													onClick={() =>
														setPagamentoSelected(pagamentoSelecionado.BOLETO)
													}
													className="pagamento-credito"
													style={{ marginRight: '10px', cursor: 'pointer' }}
												>
													<img src={boletoImg} width={35} />
													<p className="cartao-texto">Boleto</p>
												</div>
											) : tipo.tipoPagamento.codigo === 'PIX' ? (
												<div
													key={tipo.id}
													onClick={() =>
														setPagamentoSelected(pagamentoSelecionado.PIX)
													}
													className="pagamento-credito"
													style={{ marginRight: '10px', cursor: 'pointer' }}
												>
													<img src={pixImg}></img>
													<p className="cartao-texto">PIX</p>
												</div>
											) : tipo.tipoPagamento.codigo === 'CRR' ? (
												<div
													key={tipo.id}
													onClick={() =>
														setPagamentoSelected(
															pagamentoSelecionado.CREDITORECORRENTE
														)
													}
													className="pagamento-credito"
													style={{ marginRight: '10px', cursor: 'pointer' }}
												>
													<img src={cartaoIcon} width={35}></img>
													<p className="cartao-texto">Cartão recorrente</p>
												</div>
											) : tipo.tipoPagamento.codigo === 'PMF' ? (
												<div
													key={tipo.id}
													onClick={() =>
														setPagamentoSelected(
															pagamentoSelecionado.MAQUINAFISICA
														)
													}
													className="pagamento-credito"
													style={{ marginRight: '10px', cursor: 'pointer' }}
												>
													<img src={posImg}></img>
													<p className="cartao-texto">Máquina física</p>
												</div>
											) : null
										)}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	};

	if (current >= 0 && pagamentoSelected === 0) {
		return <>{tiposPagamento()}</>;
	}

	if (pagamentoSelected > 0) {
		return (
			<div className="container-fondo">
				<Header />
				<MatriculaProgresso step={3} percent={70} />
				<div className="franquia">
					<div className="user-row" id="row-aluno">
						<h3>Vamos definir o plano de pagamento</h3>
						<div className="matriculas">
							<div className="planos">
								<div className="col-1">
									<DivInicioContrato />
									<DivQtdParcelas />
									{current === tipoPagamento.PARCELADO ? (
										<DivVencimento />
									) : null}
									<div className="line"></div>
									<Row>
										<Col>{menuInputPercentual}</Col>
										<Col>{menuInputValor}</Col>
									</Row>
									<Row>
										<Col>
											<h6>
												O desconto máximo da franquia é de{' '}
												{tipoFaturamento?.valorAutonomiaDesconto}%
											</h6>
										</Col>
									</Row>
									{oferta?.flTaxa && (
										<>
											<Col style={{ width: '100%' }}>
												{current !== 1 && DivTaxa}
											</Col>
											<Col style={{ width: '100%' }}>
												{current !== 1 && DivProrrogacao}
											</Col>
										</>
									)}
									<DivCampanha />
									{!matricula.rematricula && <DivCupom />}
								</div>
								<DivResumo />
							</div>
						</div>
						<DivNavegacao />
					</div>
				</div>
				<Button
					onClick={() => setIsOpen(true)}
					className="button-fechar"
					icon={<CloseOutlined rev={undefined} />}
				/>
				<Modal
					open={isOpen}
					onClose={() => setIsOpen(false)}
					title="Você deseja cancelar a adição desta Nova Matricula?"
					subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
					textButton1="Não"
					textButton2="Sim, Cancelar"
					styleButton1="button-line"
					styleButton2="button-danger"
					link2="/alunos"
				/>
				<img src={fondo} alt="" className="img-fondo" />
			</div>
		);
	}
};
