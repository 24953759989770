import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input, Select } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUnidadeContext } from '../../context/UnidadeContext';
import { Operators } from '../../enum/operators.enum';

import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useLogService } from '../../services/log.service';
import { useStatusUnidadeService } from '../../services/status-unidade.service';
import { useUnidadeService } from '../../services/unidade.service';
import DropdownFranquia from './../../components/DropdownFranquia/index';
import { IEstadoCleanDTO, ILogCleanDTO, IStatusUnidadeCleanDTO, IUnidadeCustomDTO } from './../../models/happy-code-api.model';
import { useEstadoService } from './../../services/estado.service';
import './style.css';

export const Dashboard: React.FunctionComponent = () => {
  const { removeUnidade, setRevisao } = useUnidadeContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [data, setData] = useState<PageableResponse<IUnidadeCustomDTO>>();
  const [listEstado, setListEstado] = useState<IEstadoCleanDTO[]>();
  const [listStatusUnidade, setListStatusUnidade] = useState<IStatusUnidadeCleanDTO[]>([]);
  const [predicate] = useState<Predicate>(new Predicate());
  const [estadoId, setEstadoId] = useState<number | string>();
  const [statusUnidadeId, setStatusUnidadeId] = useState<number | string>();
  const [descricao, setDescricao] = useState<string>();
  //const [listCidade, setListCidade] = useState<ICidadeCleanDTO[]>([]);

  const history = useHistory();

  //const cidadeService = useCidadeService();
  const unidadeService = useUnidadeService();
  const estadoService = useEstadoService();
  const statusUnidadeService = useStatusUnidadeService();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso franquias",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
    
  }, [])

  const findUnidade = () => {
    setIsGlobalLoading(true);
    return unidadeService.customFind(predicate)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => setIsGlobalLoading(false));
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findUnidade();
  }

  useEffect(() => {
    const findEstados = () => {
      const predicateEstado = new Predicate(100);
      return estadoService.findClean(predicateEstado).then(({ data }) => {
        setListEstado(data.content);
      })
    }

    const findStatusUnidade = () => {
      const predicateStatusUnidade = new Predicate(100);
      return statusUnidadeService.findClean(predicateStatusUnidade).then(({ data }) => {
        setListStatusUnidade(data.content)
      })
    }
    removeUnidade();
    setRevisao(false);
    Promise.all([
      findEstados(),
      findStatusUnidade(),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (estadoId && estadoId !== 'TDS') {
      predicate.addOption('enderecoUf', estadoId);
    } else {
      predicate.removeOption('enderecoUf');
    }
    if (statusUnidadeId && statusUnidadeId !== 'TDS') {
      predicate.addOption('statusUnidadeCodigo', statusUnidadeId);
    } else {
      predicate.removeOption('statusUnidadeCodigo');
    }
    if (estadoId || statusUnidadeId) {
      findUnidade();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoId, statusUnidadeId]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      predicate.addOption('descricao', descricao || "", Operators.CONTAINS);
      findUnidade();
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findUnidade();
  }

  const onEstadoChange = (estado) => {
    const estadoSelected = estado;
    setEstadoId(estadoSelected);
  }


  const onStatusUnidadeChange = (status) => {
    setStatusUnidadeId(status);
  }

  const goToAdd = () => {
    history.push("/franquias/create");
  }

  return (
    <div className="container">
      <NewMenu selecionado={1} />
      <div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
        <div className="box-title">
          <h3 className="title">
            Franquias ({data?.totalElements})
          </h3>
          <Button id="criar-franquia" className="button-primary" onClick={goToAdd} icon={<PlusOutlined rev={undefined} />}>Nova franquia</Button>
        </div>
        <form className="filtros">
          <div className="input-text" style={{ width: '70%' }}>
            <Input id="buscar" onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar Franquia" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
          </div>
          <div className="filtro-estado" id="input-dashboard">
            <span>Estado</span>
            <Select defaultValue="TDS" onChange={onEstadoChange}>
              <Select.Option value="TDS">Todos</Select.Option>
              {listEstado && listEstado.map((item) => (
                <Select.Option key={item.id} value={item.codigo}>{item.nome}</Select.Option>
              ))}
            </Select>
          </div>
          {/* <div className="filtro-estado" id="input-dashboard">
            <span>Cidade</span>
            <Select defaultValue="TDS" onChange={onCidadeChange}>
              <Select.Option value="TDS">Todos</Select.Option>
              {listCidade && listCidade.map((item) => (
                <Select.Option key={item.id.toString()} value={item.nome}>{item.nome}</Select.Option>
              ))}
            </Select>
          </div> */}
          <div className="filtro-estado" id="input-dashboard">
            <span>Status</span>
            <Select defaultValue="TDS" onChange={onStatusUnidadeChange}>
              <Select.Option value="TDS">Todos</Select.Option>
              {listStatusUnidade && listStatusUnidade.map((item) => (
                <Select.Option key={item.id.toString()} value={item.codigo}>{item.descricao}</Select.Option>
              ))}
            </Select>
          </div>
        </form>

        <div className="tabela">
          <div className="titles">
            <div className="item" style={{ width: "25%" }}>
              <span>NOME DA FRANQUIA</span>
              <div className="arrows" onClick={() => ordenar('descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "3%" }}>

            </div>
            <div className="item" style={{ width: "10%" }}>
              <span>CODIGO</span>
              <div className="arrows" onClick={() => ordenar('codigo')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "13%" }}>
              <span>ESTADO</span>
              <div className="arrows" onClick={() => ordenar('endereco.uf')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "15%" }}>
              <span>CIDADE</span>
              <div className="arrows" onClick={() => ordenar('endereco.cidade')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "20%" }}>
              <span>GESTOR</span>
              <div className="arrows" onClick={() => ordenar('gestor.nome')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "10%" }}>

            </div>
          </div>
          <div className="tabela-body">
            {data && data.content.map((unidade, index) => (
              <DropdownFranquia unidade={unidade} key={index} />
            ))}
          </div>
        </div>
        <div className="paginacao">
          <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
        </div>
      </div>
    </div>
  );
}