import Style from './stylePagamento.module.css';

interface StatusMessage {
	RECO:{
		message: JSX.Element;
	}
	PGO: {
		message: JSX.Element;
	};
	CAN: {
		message: JSX.Element;
	};
	IST: {
		message: JSX.Element;
	};
	RPC: {
		message: JSX.Element;
	};
}

 const statusMessage:StatusMessage = {
	RECO: {
		message: (
			<div className={Style.container_any}>
				<p>Este plano financeiro ja está em recorrência de pagamento no cartão.</p>
				<p>Em caso de dúvidas ou alterações, contate seu HUB.</p>
			</div>
		),
	},
	PGO: {
		message: (
			<div className={Style.container_any}>
				<p>Obrigado!</p>
				<p>Seu pagamento foi confirmado.</p>
			</div>
		),
	},
	CAN: {
		message: (
			<div className={Style.container_any}>
				<p>Cobrança cancelada!</p>
				<p>Em caso de dúvidas, contate seu HUB.</p>
			</div>
		),
	},
	IST: {
		message: (
			<div className={Style.container_any}>
				<p>Cobranca isenta!</p>
				<p>Em caso de dúvidas, contate seu HUB.</p>
			</div>
		),
	},
	RPC: {
		message: (
			<div className={Style.container_any}>
				<p>Cobrança repactuada!</p>
				<p>Em caso de dúvidas, contate seu HUB.</p>
			</div>
		),
	},
};
export default statusMessage;