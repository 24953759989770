import { IMatriculaCustomDTO } from '../../../../../../models/happy-code-api.model';
import { useAlunoContext } from '../../../../../../context/AlunoContext';
import { useHistory } from 'react-router-dom';

export default function useCourseCard() {
	const courseCardBorderLeftColor = {
		'Happy Code': 'var(--happy-code)',
		'Happy Money': 'var(--happy-money)',
		'Happy English': 'var(--happy-english)',
		'Happy Speech': 'var(--happy-speech)',
	};

	const { setDetalheMatricula } = useAlunoContext();
	const { push } = useHistory();
	const navigateToDetalheMatriculaPage = (matricula: IMatriculaCustomDTO) => {
		push(`/alunos/detalhe-matricula/${matricula.id}/${matricula.financeiro.id}`);
		setDetalheMatricula(matricula);
	};

	return {
		courseCardBorderLeftColor,
		navigateToDetalheMatriculaPage,
	};
}
