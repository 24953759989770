import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICartaoDTO,
	ICobrancaCleanDTO,
	ICobrancaCustomDTO,
	ICobrancaDTO,
	ICobrancaFullDTO,
	ICobrancaKpiDTO,
	ICobrancaPagarMeDTO,
	IEstornoDTO,
	IRequestChangePaymentDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { Predicate } from '../models/predicate.model';
import { formatHttpParams } from './../models/predicate.model';
import { PageableResponse } from './../models/response.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCobrancaService = () => {
	const resourceURL = 'cobranca';

	const findCustom = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<PageableResponse<ICobrancaCustomDTO>>> => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	};

	const findCustomComFinanceiro = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<PageableResponse<ICobrancaCustomDTO>>> => {
		return http.get(
			`${resourceURL}/custom-com-financeiro`,
			formatHttpParams(predicate)
		);
	};

	const findCheckout = (
		codigoPedido: string,
		isencaoAcrescimos: string,
		token: string
	): Promise<AxiosResponse<ICobrancaPagarMeDTO>> => {
		return http.get(
			`${resourceURL}/checkout?codigoPedido=${codigoPedido}&isencao=${isencaoAcrescimos}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	};

	const findByIdOpen = (
		id: number,
		token: string
	): Promise<AxiosResponse<ICobrancaDTO>> =>
		http.get(`${resourceURL}/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

	const findStatus = (
		cobrancaId: number,
		token: string
	): Promise<AxiosResponse<string>> => {
		return http.get(`${resourceURL}/checkout/status?cobrancaId=${cobrancaId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
	};

	const pagarme = (
		cobrancaPagarMeDTO: ICobrancaPagarMeDTO,
		token: string
	): Promise<AxiosResponse<ICobrancaPagarMeDTO>> => {
		return http.post(`${resourceURL}/pagarme`, cobrancaPagarMeDTO, {
			headers: { Authorization: `Bearer ${token}` },
		});
	};

	const atualizaVencimento = (
		cobranca: any,
		token: string
	): Promise<AxiosResponse<ICobrancaDTO>> =>
		http.patch(
			`${resourceURL}/${cobranca.id}`,
			{
				statusCobranca: { id: 1 },
				quantidadeReemissao: cobranca.quantidadeReemissao + 1,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

	const byAluno = (
		predicate: Predicate = new Predicate(),
		idAluno: number
	): Promise<AxiosResponse<PageableResponse<ICobrancaCustomDTO>>> => {
		return http.get(
			`${resourceURL}/custom/${idAluno}/`,
			formatHttpParams(predicate)
		);
	};

	const getKpi = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<ICobrancaKpiDTO>> => {
		return http.get(`${resourceURL}/kpi`, formatHttpParams(predicate));
	};

	const patch = (
		toUpdate: any
	): Promise<IAxiosResponseCustom<ICobrancaCleanDTO>> =>
		http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate);

	const cancelarRecorrencia = (
		idCobranca: number
	): Promise<IAxiosResponseCustom<ICobrancaCleanDTO>> =>
		http.post(`${resourceURL}/cancelar-recorrencia/${idCobranca}`);

	const cancelarOrder = (
		order: string
	): Promise<IAxiosResponseCustom<string>> =>
		http.post(`${resourceURL}/cancelar-order/${order}`);

	const editarCartaoAssinatura = (
		idCobranca: number,
		cartaoDTO: ICartaoDTO
	): Promise<IAxiosResponseCustom<any>> =>
		http.post(
			`${resourceURL}/editar-cartao-assinatura/${idCobranca}`,
			cartaoDTO
		);

	const atualizarVencimento = (
		cobrancaId: number,
		novoVencimento: any
	): Promise<IAxiosResponseCustom<any>> =>
		http.post(
			`${resourceURL}/atualizar-vencimento?cobrancaId=${cobrancaId}&novoVencimento=${novoVencimento}`
		);

	const reiniciarCobranca = (
		cobrancaId: number
	): Promise<IAxiosResponseCustom<any>> =>
		http.post(`${resourceURL}/reiniciar-cobranca?cobrancaId=${cobrancaId}`);

	const estornarCobranca = (
		dto: IEstornoDTO
	): Promise<IAxiosResponseCustom<any>> =>
		http.post(`${resourceURL}/estornar`, dto);

	const findByMatriculaId = (
		idMatricula: number
	): Promise<AxiosResponse<ICobrancaDTO[]>> => {
		return http.get(
			resourceURL + `/cobranca-id-matricula?idMatricula=${idMatricula}`
		);
	};

	const findByMatriculaIds = (
		idMatricula: number
	): Promise<AxiosResponse<ICobrancaDTO[]>> => {
		return http.get(
			resourceURL + `/cobranca-id-matriculas?idMatricula=${idMatricula}`
		);
	};

	
	const findCobrancasByIdFinanceiro = (
		idFinanceiro: number
	): Promise<AxiosResponse<ICobrancaDTO[]>> =>
		http.get(resourceURL + `/id-financeiro/${idFinanceiro}`);
		
		const atualizarTipoPagamento = (body: IRequestChangePaymentDTO) => {
			return http.post(`${resourceURL}/atualizar-tipo-pagamento`,
				body
			);
		}
	return {
		...baseFind<ICobrancaCleanDTO, ICobrancaDTO, ICobrancaFullDTO>(resourceURL),
		...baseFindById<ICobrancaCleanDTO, ICobrancaDTO, ICobrancaFullDTO>(
			resourceURL
		),
		...baseFindList<ICobrancaCleanDTO, ICobrancaDTO, ICobrancaFullDTO>(
			resourceURL
		),
		getKpi,
		findCustom,
		patch,
		byAluno,
		findCheckout,
		findStatus,
		pagarme,
		cancelarRecorrencia,
		editarCartaoAssinatura,
		atualizarVencimento,
		reiniciarCobranca,
		estornarCobranca,
		findByMatriculaId,
		cancelarOrder,
		findByMatriculaIds,
		findCustomComFinanceiro,
		findCobrancasByIdFinanceiro,
		findByIdOpen,
		atualizarTipoPagamento
	};
};
