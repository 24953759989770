import { CloseOutlined } from '@ant-design/icons';
import { Button, Progress, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useNegocioContext } from '../../context/NegocioContext';
import '../../global.css';
import { IUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { useUnidadeService } from '../../services/unidade.service';
import StepsCreate from './steps-create';
import './style.css';

const NegocioCreateStep2: React.FunctionComponent = () => {

  const { negocio, setNegocio, revisao } = useNegocioContext();

  const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>();
  const unidadeService = useUnidadeService();

  const [isOpen, setIsOpen] = useState(false);

  const onUnidadesChange = (op) => {
    setNegocio({ ...negocio, negocioUnidades: op.map(o => ({ id: null, ativo: true, unidadeId: o.key, unidadeDescricao: o.value })) })
  }

  const optionsUnidade = unidades?.map(u => <Select.Option key={u.id} value={u.descricao}>{u.descricao}</Select.Option>)

  useEffect(() => {
    unidadeService.findCleanList().then((response) => setUnidades(response.data));
  }, [])

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={32} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={1} />
      <div className="franquia">        
        <div className="user-row">
          <h3 id="taxas-h3">Selecione as unidades</h3>        
          <h3 id="taxas-h3">Unidades</h3>
            <div className="input-box">
              <p>Unidades</p>
              <Select mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecione as unidades"
                defaultValue={negocio?.negocioUnidades?.map(s => (s?.unidadeDescricao))}
                value={negocio?.negocioUnidades?.map(s => (s?.unidadeDescricao))}
                onChange={(value, op) => onUnidadesChange(op)}>
                {optionsUnidade}
              </Select>
            </div>
          <div className="botoes">
            {revisao ?
              <Link to="/negocio/create/step-6">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/negocio/create/step-1">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/negocio/create/step-6">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação desse negócio?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/negocio'
      />
    </div>
  )

}

export default NegocioCreateStep2;