import * as Unicons from '@iconscout/react-unicons';
import { Button, notification } from 'antd';
import { useState } from 'react';
import { Tags } from '../../components/Tags/index';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { usePessoaService } from '../../services/pessoa.service';
import { Avatar } from '../Avatar';
import ModalCreate from '../ModalCreate/index';
import { isAfterDate } from './../../util/format';
import './style.css';
import { useNotificationService } from '../../services/notification.service';
interface Props {
	id?: number;
};

const MenuDetalheColaborador: React.FunctionComponent<Props> = ({
	id,
}: Props) => {
	const { colaborador, removeColaborador } = useColaboradorContext();
	const { setIsGlobalLoading } = useGlobalContext()
	const [isOpen, setIsOpen] = useState(false);
	const pessoaService = usePessoaService();
	const notification = useNotificationService();
	

	const save = () => {
		if(!colaborador?.email){
			return notification({
                description: `O campo "Email Corporativo" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });		
		}
		const formatedCPF = colaborador.cpf;
		colaborador.cpf = formatedCPF.replace(/[.-\s]/g, '');
		setIsGlobalLoading(true);
		if (id) {
			pessoaService.patchClean(colaborador).then(() => {
				removeColaborador();
				setIsOpen(true);
			}).finally(() => setIsGlobalLoading(false));
		} else {
			pessoaService.create(colaborador).then(() => {
				removeColaborador();
				setIsOpen(true);
			}).finally(() => setIsGlobalLoading(false));;
		}
	};

	return (
		<div className="menu-box-revisao-float" id="menu-colaborador">
			<div className="bloco-1">
				<Unicons.UilEllipsisH
					size="20"
					color="rgba(52, 68, 83, 0.5)"
					className="mas"
				/>
				<Avatar size={115} arquivo={colaborador?.foto} />
				<h1 id="title">{colaborador.nome}</h1>
				<div id="standy-campanha">
					{isAfterDate(colaborador.dataAdmissao) ? (
						<div style={{ display: 'flex' }}>
							<div style={{ alignSelf: 'center' }} id="dot-verde-campanhas"></div>
							<p>Ativo</p>
						</div>
					) : (
						<div style={{ display: 'flex' }}>
							<div style={{ alignSelf: 'center' }} id="dot-orange-campanhas"></div>
							<p>A Iniciar</p>
						</div>
					)}
				</div>
				<h6 style={{ marginBottom: 0 }}>Email de Acesso</h6>
				<span style={{ wordWrap: "break-word" }}>{colaborador.email}</span>
				<div id="tag-colaborador-menu">
					<Tags
						tipo={
							colaborador.unidadePessoas[0]?.tipoUnidadePessoaDescricao || ''
						}
					/>
				</div>
				{/* <Button className="button-line" style={{ width: 260 }} icon={<Unicons.UilEditAlt size="20" color="var(--primary-color)" />}>
					Editar
				</Button> */}
				<Button id="salvar" className="button-primary" onClick={save} style={{ width: 260 }}>Salvar Alterações</Button>
			</div>
			<ModalCreate
				open={isOpen}
				//onClose={() => setIsOpen(false)}
				title="Colaborador salvo com sucesso!"
				textButton1="Criar outro colaborador"
				textButton2="Voltar a página inicial"
				styleButton1="button-line"
				styleButton2="button-primary"
				link1="/colaboradores/create/step-1"
				link2="/colaboradores"
			/>
		</div>
	);
};

export default MenuDetalheColaborador;