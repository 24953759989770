
import http from '../helpers/http-common';
import { IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

interface Props {
    id?: number;
}

export const useAcademyMaterialService = () => {
	const resourceURL = '/material';

	const editarArquivo = (
		materialId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IMaterialDTO>> =>
		http.patch(`${resourceURL}/patch-custom/${materialId}`, body);

	const enviarArquivo = (
		body: any,
	): Promise<IAxiosResponseCustom<IMaterialDTO>> =>
		http.post(`${resourceURL}`, body);

	return {
		...baseFind<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(resourceURL),
		...baseFindList<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(resourceURL),
		...baseFindById<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(resourceURL),
		createFull: (body): Promise<IAxiosResponseCustom<IMaterialDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IMaterialDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IMaterialDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`),
		editarArquivo,
		enviarArquivo
	};
};
