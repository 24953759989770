import ClipLoader from "react-spinners/ClipLoader";
import { ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { formatMoney, formattedDate } from '../../util/format';
import Style from './style.module.css';
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface Props {
    cobranca: ICobrancaPagarMeDTO;
    finaliza: () => void;
    recaptchaRef?: any;
    setCurrent: (current: any) => void;
    step: any;
    btnVisible: boolean;
    parcela: number;
    originalVencimento: Date;
}

export const formatPagamentoDS = (cobranca: ICobrancaPagarMeDTO) => {
    switch (cobranca?.tipoPagamento?.codigo) {
        case 'CRC':
            return 'Cartão de Crédito'
        case 'CRR':
            return 'Crédito Recorrente'
        case 'BOL':
            return 'Boleto'
        case 'PIX':
            return 'Pix'
        case 'PMF':
            return 'Máquina Física'
        default:
            return 'Tipo desconhecido'
    }
}
const Step3 = ({ cobranca, finaliza, recaptchaRef, setCurrent, step, btnVisible, parcela, originalVencimento }: Props) => {

    return (
        <div className={Style.container_rev}>
            <div className={Style.first_line}>
                <div>
                    <h5>PARCELA À PAGAR</h5>
                    <p className='ti5'>{cobranca?.cobrancaUnica ? 'Parcela 1/1' : `Parcela  ${parcela}/${cobranca?.quantidadeParcelas}`}</p>
                </div>
                {originalVencimento && (
                    <div>
                    <h5>VENC ORIGINAL DA PARCELA  <Tooltip style={{cursor: 'pointer', textAlign: 'center'}} title="Data de vencimento original"><InfoCircleOutlined rev={false}/></Tooltip></h5>
                    <p className='ti5'>{formattedDate(originalVencimento)}</p>
                </div>
                )}
                <div>
                    <h5>VENC ATUALIZADA</h5>
                    <p className='ti5'>{cobranca?.dataVencimento ? formattedDate(cobranca?.dataVencimento) : 'ERRO: dado não encontrado.'}</p>
                </div>
                <div>
                    <h5>VALOR DA PARCELA</h5>
                    <p className='ti5'>{`${cobranca?.cobrancaUnica ? cobranca?.parcela + 'x de ' + formatMoney(cobranca?.valorCobranca / cobranca?.parcela) : formatMoney(cobranca?.valorCobranca)}`}</p>
                </div>
            </div>

            <div className={Style.second_line}>
                <div>
                    <h5>FORMA DA PAGAMENTO</h5>
                    <p className='ti5'>{formatPagamentoDS(cobranca)}</p>
                </div>
            </div>

            {/* <div className={Style.container_captcha}>
                            <ReCaptchaV2  ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY}/>
                        </div> */}
            {btnVisible ? <button disabled={true} className={Style.btn_step3}><ClipLoader color='#FFF' /></button>
                : <button onClick={finaliza} className={Style.btn_step3}>concluir pagamento</button>}
            {window.innerWidth < 768 ? <button onClick={() => setCurrent(step.PAGAMENTO)} className={Style.btn_step3_voltar}>VOLTAR</button> : null}
        </div>
    )
}

export default Step3