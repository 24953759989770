import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import { ICobrancaCustomDTO, IRequestChangePaymentDTO, ITipoPagamentoCleanDTO } from '../../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../../models/predicate.model';
import { useNotificationService } from '../../../../../../services/notification.service';
import { useTipoPagamentoService } from '../../../../../../services/tipo-pagamento.service';
import { useCobrancaService } from '../../../../../../services/cobranca.service';

export default function useModalChangePaymentHook() {
	const tipoPagamentoService = useTipoPagamentoService();
	const cobrancaService = useCobrancaService();
	const [tipoPagamento, setTipoPagamento] = useState<ITipoPagamentoCleanDTO | null>(null);
	const { setIsGlobalLoading } = useGlobalContext();
	const [alterAll, setAlterAll] = useState(false);
	const notification = useNotificationService();
	const [isRecorrente, setIsRecorrente] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);

	const findPaymentTypes = async (): Promise<AxiosResponse<ITipoPagamentoCleanDTO[]>> => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		setIsGlobalLoading(true);
		try {
			const response = await tipoPagamentoService.findCleanList(predicate).finally(() => setIsGlobalLoading(false));
			return response;
		} finally {
			setIsGlobalLoading(false);
		}
	};

	const cancelRecorencia = async (cobranca: ICobrancaCustomDTO) => {
		setIsGlobalLoading(true);
		try {
			await cobrancaService.cancelarRecorrencia(cobranca?.id);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	const handlePayment = async (cobranca: ICobrancaCustomDTO, onclose: () => void) => {
		if (!tipoPagamento) {
			return notification({
				description: `Por favor, selecione um tipo de pagamento!`,
				type: 'warning',
				message: 'Aviso!',
			});
		}
		setIsGlobalLoading(true);
		if (isRecorrente) {
		 await cancelRecorencia(cobranca);
		}

		const body: IRequestChangePaymentDTO = {
			alterarTodasCobrancas: alterAll || isRecorrente,
			tipoPagamento: tipoPagamento,
			cobrancaCustomDTO: cobranca,
		};
		try {
			await cobrancaService.atualizarTipoPagamento(body);
			notification({
				description: `O tipo de pagamento foi atualizado com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsGlobalLoading(false);
			setTipoPagamento(null);
			setAlterAll(false);
			setIsRecorrente(false);
			setOpenConfirm(false);
			onclose();
		}
	};

	const handlePaymentSelection = (item: ITipoPagamentoCleanDTO) => {
		setTipoPagamento(item);
	};

	return {
		findPaymentTypes,
		handlePaymentSelection,
		handlePayment,
		setAlterAll,
		setTipoPagamento,
		setIsRecorrente,
		setOpenConfirm,
		alterAll,
		tipoPagamento,
		isRecorrente,
		openConfirm,
	};
}
